<template>
  <ul class="menu-nav">
    <router-link
      to="/dashboard"
      v-slot="{ href, navigate, isActive, isExactActive }"
    >
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item"
        :class="[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active',
        ]"
      >
        <a class="menu-link" @click="showGetStartedClicked">
          <i class="menu-icon flaticon-info" @click="showGetStartedClicked"></i>
          <v-icon
            v-if="selectedSidebarComponent === 'getStarted'"
            style="color: #404040; margin-top: 4px"
            @click="showGetStartedClicked"
            id="v-step-1"
          >
            mdi-information-outline</v-icon
          >
          <span class="menu-text">Program Info 2</span>
        </a>
        <span
          v-if="selectedSidebarComponent === 'getStarted'"
          style="color: #0755a3"
        >
          <program-descriptions
            v-bind:namespace="activeModuleName"
          ></program-descriptions>
        </span>
      </li>
    </router-link>

    <div style="width: auto; margin: 5px 0 0 0">
      <div style="width: auto; text-align: left; border-bottom: 1px solid gray">
        <div
          class="px-2"
          style=""
          v-bind:style="{
            borderBottom: mini === true ? '0px solid gray' : '1px solid gray',
            backgroundColor: mini === true ? '#c7d5e7' : '#EBEBEB',
          }"
        >
          <span
            v-if="
              $store.state.user.role === 'superadmin' &&
              selectedSidebarComponent === 'endpoint'
            "
            style="text-align: center"
          >
            <span style=""
              ><v-icon
                v-if="
                  selectedSidebarComponent === 'endpoint' &&
                  $store.state.user.role === 'superadmin'
                "
                v-b-tooltip.hover.topleft="'endpoint'"
                @click="showEndpointClicked"
                style="color: #404040"
                >mdi-access-point</v-icon
              ></span
            >
            <endpoint-selector
              style="margin: 3px 10px 30px 0px"
            ></endpoint-selector>
          </span>

          <span
            v-if="selectedSidebarComponent === 'settings'"
            style="text-align: center"
          >
            <v-icon
              v-if="selectedSidebarComponent === 'settings'"
              v-b-tooltip.hover.topleft="'settings'"
              @click="showSettingsClicked"
              style="color: #404040; margin-bottom: 8px"
              >mdi-cog-outline</v-icon
            >
            <settings-panel></settings-panel>
          </span>

          <span v-if="selectedSidebarComponent === 'chartSharing'">
            <v-icon
              v-if="selectedSidebarComponent === 'chartSharing'"
              @click="showChartSharingClicked"
              style="color: #404040"
              >mdi-share-circle</v-icon
            >
            <div
              style="
                width: 90%;
                margin: -23px 8px 0px 16px;
                border: 0px solid darkgray;
                font-size: 1rem;
                text-align: left;
                color: rgb(7, 85, 163);
              "
            >
              <state-editor v-bind:namespace="activeModuleName"></state-editor>
            </div>
          </span>

          <span
            v-if="selectedSidebarComponent === 'chartSaving'"
            style="margin: 0 0 0 0px"
          >
            <v-icon
              v-if="selectedSidebarComponent === 'chartSaving'"
              id="v-step-4"
              style="color: #404040; margin-top: 4px"
              @click="showChartSavingClicked"
              >mdi-content-save-outline</v-icon
            >
            <saved-charts v-bind:namespace="activeModuleName"></saved-charts>
          </span>
        </div>

        <v-list-item
          v-if="selectedSidebarComponent !== 'chartSaving'"
          id="v-step-4"
          class="px-2"
          style="background-color: #c7d5e7; border-bottom: 1px solid gray"
        >
          <v-icon
            v-b-tooltip.hover.topleft="'Saved Tabs'"
            style="color: #404040"
            @click="showChartSavingClicked"
            >mdi-content-save-outline</v-icon
          >
          <span
            @click="showChartSavingClicked"
            style="
              cursor: pointer;
              font-weight: bold;
              font-size: 1.3rem;
              text-align: center;
              margin: 0px 0px -2px 4px;
              color: #404040;
            "
            >Saved Tabs</span
          >
        </v-list-item>

        <v-list-item
          v-if="
            selectedSidebarComponent !== 'chartSharing' &&
            $store.state.user.role === 'superadmin'
          "
          class="px-2"
          style="
            background-color: #c7d5e7;
            padding: 0 0 5px 0;
            border-bottom: 1px solid gray;
          "
        >
          <v-icon
            v-b-tooltip.hover.topleft="'Chart Sharing'"
            style="color: #404040"
            @click="showChartSharingClicked"
            >mdi-share-circle</v-icon
          >
          <span
            @click="showChartSharingClicked"
            style="
              cursor: pointer;
              font-weight: bold;
              font-size: 1.3rem;
              text-align: center;
              margin: 0px 0 -5px 5px;
              color: #404040;
            "
            >Chart Sharing</span
          >
        </v-list-item>

        <v-list-item
          v-if="
            selectedSidebarComponent !== 'endpoint' &&
            $store.state.user.role === 'superadmin'
          "
          class="px-2"
          style="background-color: #c7d5e7; border-bottom: 1px solid gray"
        >
          <v-icon
            v-b-tooltip.hover.topleft="'endpoint'"
            style="color: #404040"
            @click="showEndpointClicked"
            >mdi-access-point</v-icon
          >
          <span
            @click="showEndpointClicked"
            style="
              cursor: pointer;
              font-weight: bold;
              font-size: 1.3rem;
              margin: 0px 0px -2px 5px;
              color: #404040;
            "
            >Endpoint</span
          >
        </v-list-item>

        <v-list-item
          v-if="selectedSidebarComponent !== 'settings'"
          class="px-2"
          style="background-color: #c7d5e7"
        >
          <v-icon
            v-b-tooltip.hover.topleft="'settings'"
            style="color: #404040; margin: -5px 0 0 0"
            @click="showSettingsClicked"
            >mdi-cog-outline</v-icon
          >
          <span
            @click="showSettingsClicked"
            style="
              cursor: pointer;
              font-weight: bold;
              font-size: 1.3rem;
              margin: 0px 0px -2px 5px;
              color: #404040;
            "
            >Settings</span
          >
        </v-list-item>
      </div>

      <!-- <saved-charts-search v-bind:namespace="namespace" 
                                       v-bind:index="'0'"></saved-charts-search> -->
    </div>
  </ul>
</template>

<style>
.toggle {
  cursor: pointer;
}
</style>

<script>
import * as fb from "@/firebase";
// import savedChartsSearch from "@/components/saved-charts-search.vue";
const StateEditor = () => import("@/components/state-editor");
import endpointSelector from "@/components/endpoint-selector";
import settingsPanel from "@/components/settings-panel";
import savedCharts from "@/components/saved-charts.vue";
import programDescriptions from "@/components/program-descriptions.vue";

export default {
  components: {
    StateEditor,
    endpointSelector,
    settingsPanel,
    savedCharts,
    programDescriptions,
  },
  mounted() {
    // console.log("mounted() starting. this.namespace=", this.namespace);
    this.userEmail = this.$store.state.user.email;
    // this.updateSelectedSidebarComponentToFirebase();

    // console.log("this.selectedSidebarComponent=", this.selectedSidebarComponent);
    if (this.selectedSidebarComponent !== null) {
      this.mini = false;
    }
  },
  props: {
    namespace: {
      type: String,
    },
    defaultAddTreeNodeTitle: {
      type: String,
      default: "Add Folder",
    },
  },
  data: function () {
    return {
      json: "",
      saveName: "",
      hide: "hide",
      show: "show",
      saveShow: true,
      savedCharts: [],
      selectedDisplayOptions: ["tree"],
      // layout: [],
      oldName: "",
      newTree: {},
      data: null,
      initialized: false,
      users: [],
      userEmail: "",
      drawer: true,
      mini: true,
      sidebarOptions: [
        { value: "getStarted", text: "Program Description" },
        { value: "chartSaving", text: "Saved Tabs" },
        { value: "chartSharing", text: "Chart Sharing" },
        { value: "endpoint", text: "Endpoint" },
        { value: "settings", text: "Settings" },
      ],
    };
  },

  firestore: {
    users: fb.usersCollection,
  },
  computed: {
    selectedSidebarTitle() {
      // console.log("selectedSidebarTitle() starting. this.selectedSidebarComponent=", this.selectedSidebarComponent);
      let option = this.sidebarOptions.find(
        (x) => x.value === this.selectedSidebarComponent
      );
      // console.log("option=", option);
      return typeof option !== "undefined" ? option.text : "";
    },
    usersOptions() {
      return this.users.map((x) => x.email);
    },
    email() {
      // console.log("this.$store.state.user.email=", this.$store.state.user.email);
      return this.$store.state.user.role === "superadmin"
        ? this.userEmail
        : this.$store.state.user.email;
    },
    selectedSidebarComponent: {
      get() {
        return this.$store.state.user.selectedSidebarComponent;
      },
      set() {
        // console.log("selectedSidebarComponent set() starting.");
      },
    },
    program() {
      // console.log("program=", this.$store.state[this.namespace].program);
      return typeof this.$store.state[this.namespace] === "undefined" ||
        typeof this.$store.state[this.namespace].program === "undefined"
        ? "Tabs"
        : this.$store.state[this.namespace].program;
    },
    activeModuleName() {
      let activeModuleName = this.$store.getters["activeModuleName"];
      // console.log("activeModuleName=", activeModuleName);
      return activeModuleName;
    },
  },
  watch: {
    selectedSidebarComponent: function (selectedSidebarComponent) {
      console.log("watch selectedSidebarComponent=", selectedSidebarComponent);
      if (selectedSidebarComponent !== null) {
        this.mini = false;
        this.selectedSidebarComponent = selectedSidebarComponent;
      } else {
        this.mini = true;
        this.selectedSidebarComponent = null;
      }
    },
  },
  methods: {
    toggleSidebar() {
      console.log("toggleSidebar() starting.");
      if (this.mini === false) {
        this.selectedSidebarComponent = null;
        this.updateSelectedSidebarComponentToFirebase(null);
        this.mini = true;
      } else {
        this.mini = false;
      }
    },
    showSidebarComponentClicked(component) {
      console.log(
        "showSidebarComponentClicked() starting. component=",
        component
      );
      let newSelectedSidebarComponent;
      if (this.selectedSidebarComponent === component) {
        newSelectedSidebarComponent = null;
        this.mini = true;
      } else {
        newSelectedSidebarComponent = component;
      }
      this.updateSelectedSidebarComponentToFirebase(
        newSelectedSidebarComponent
      );
    },
    showGetStartedClicked() {
      this.showSidebarComponentClicked("getStarted");
    },
    showChartSharingClicked() {
      console.log("showChartSharingClicked() starting.");
      this.showSidebarComponentClicked("chartSharing");
    },
    showChartSavingClicked() {
      console.log("showChartSavingClicked() starting.");
      this.showSidebarComponentClicked("chartSaving");
    },
    showEndpointClicked() {
      console.log("showEndpointClicked() starting.");
      this.showSidebarComponentClicked("endpoint");
    },
    showSettingsClicked() {
      console.log("showSettingsClicked() starting.");
      this.showSidebarComponentClicked("settings");
    },
    updateSelectedSidebarComponentToFirebase(component) {
      console.log(
        "updateSelectedSidebarComponentToFirebase() starting. component=",
        component
      );
      this.selectedSidebarComponent = component;
      fb.usersCollection
        .doc(this.email)
        .update({ selectedSidebarComponent: component })
        .then(() => {
          console.log("selectedSidebarComponent updated.");
        })
        .catch((error) => {
          // The document probably doesn't exist.
          console.error("Error updating document: ", error);
        });
    },
  },
};
</script>
